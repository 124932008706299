@import '@core/scss/core.scss';
@import './assets/scss/styles';
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import '@core/scss/angular/libs/select.component.scss';
@import '@core/scss/base/pages/app-invoice.scss';
@import '@core/scss/angular/libs/flatpickr.component.scss';
@import "~ag-grid-community/styles/ag-grid.css";
@import "~ag-grid-community/styles/ag-theme-alpine.css";
@import '@core/scss/angular/libs/quill-editor.component.scss';
/* Apply the custom font to ag-Grid elements */
// .ag-theme-alpine .ag-root,
// .ag-theme-alpine .ag-cell,   
// .ag-theme-alpine .ag-header-cell {
//   font-family: 'Roboto', sans-serif;
// }
// *{
//     font-family: 'Roboto' !important;
// }

:root {
    --white: #ffffff;
    --black: #000000;
    --grey: #025e5e;
    --red: #b52735;
    --deepRed:red;
    --purple: purple;
    --green: #d39959;
    --lite-grey: #77abab;
    --lite-green: #a4c475;
    --link-color: #0056b3;
    --info-bg: #f3f2f7;
}
.required{
  color: var(--deepRed);
}
.delete-order-icon
{
  color:var(--deepRed);
  margin-left:5px
}
.form-control{
  height: 38px !important; 
}
.ql-snow{
  height: fit-content;
}
.gap-10{
  gap: 10px;
}
.gap-20{
  gap: 20px;
}
.status-badge{
  padding: 4px;
  border-radius: 4px;
  font-size: 10px;
  font-weight: bold;
}
.clickable-icon{
  cursor: pointer;
}
// .dropdown-toggle::after {
//     top: 5px !important;
//     left: 0.5rem !important;
// }
.custom-border {
  border: 1px solid var(--info-bg);
  // background-color: var(--info-bg);
  width: 100%;
  border-radius: 3px;

}

.page-size-adjust{
    position: relative; 
    width: 30%; 
    top: -35px; 
    margin-left: 20px;
  }
  .ag-header-cell.center-aligned-header {
    .ag-header-cell-label {
      justify-content: center;
    }
  }


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.ag-root-wrapper-body.ag-layout-normal {
    flex: 1 1 auto;
    height: auto !important;
    min-height: 100% !important;
  }

  .wp-normal{
    white-space: normal;
  }